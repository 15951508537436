export const routes = {
  welcome: '/welcome',
  comingSoon: '/coming-soon',
  accessDenied: '/access-denied',
  notFound: '/not-found',
  maintenance: '/maintenance',
  blank: '/blank',
  signIn: '/signin',
  auth: {
    forgotPassword: '/auth/forgot-password',
    signUp: '/auth/sign-up',
  },
  profile: '/profile',
  forms: {
    profileSettings: '/account/profile-settings',
  },
  customer_home: '/inventory/home',
  admin_home: '/admin/customer/list',
};
