'use client';
import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { Role, userHasRole } from '../types/role.enum';
import Link from 'next/link';
import Image from 'next/image';
import { siteConfig } from '@/config/site.config';
import { Button, Title } from 'rizzui';
import { PiArrowsClockwise } from 'react-icons/pi';
import { routes } from '@/config/routes';
import LoadingCylonIcon from '../components/icons/loading-cylon';

export default function Page() {
  const router = useRouter();
  const { data: session, status } = useSession();

  useEffect(() => {
    if (status === 'loading') return; // 세션 로딩 중에는 아무 동작도 하지 않음

    if (!session) {
      // 세션이 없으면 로그인 페이지로 이동
      router.push(routes.signIn);
    } else {
      // 세션이 있는 경우 권한에 따라 페이지 이동
      if (userHasRole(Role.HeadOffice, session.user?.roles)) {
        router.replace(routes.admin_home);
      } else {
        router.replace(routes.customer_home);
      }
    }
  }, [session, status, router]);

  return (
    <div className='flex min-h-screen flex-col bg-[#F8FAFC]'>
      <div className='sticky top-0 z-40 flex justify-center py-5 backdrop-blur-lg lg:backdrop-blur-none xl:py-10'>
        <Link href='/'>
          <Image src={siteConfig.logo} alt={siteConfig.title} className='dark:invert' priority />
        </Link>
      </div>

      <div className='flex grow items-center px-6 xl:px-10'>
        <div className='mx-auto text-center'>
          <LoadingCylonIcon className='w-64 h-64 text-gray-400' />

          <Title as='h1' className='text-[22px] font-bold leading-normal text-gray-1000 lg:text-3xl'>
            Loading...
          </Title>
          <Button as='span' size='xl' color='primary' variant='text' className='mt-8 h-12 px-4 xl:h-12 xl:px-6' onClick={()=>window.location.reload()}>
            <PiArrowsClockwise className='mr-1.5 text-lg' />
            Retry
          </Button>
        </div>
      </div>
    </div>
  );
}
