export enum Role {
    HeadOffice = 'headoffice', //본사
    Manager = 'manager', //관리자, 직원 관리 가능
}

export function rolesToString(roles: Role[]): string {
    return roles.join(',');
}

function isRole(value: string): value is Role {
    return Object.values(Role).includes(value as Role);
}


export function stringToRoles(strings: string): Role[] {
    return strings.split(',').filter(isRole);
}

export function userHasRole(role:Role, user_roles?:string){
    if(!user_roles) return false;

    return user_roles.split(',').includes(role);
}